import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
// import Image from "../components/image"
import SEO from '../components/seo'
import Youtube from '../components/Youtube/Youtube'
import Nav from '../components/Nav/Nav'
import LangSwitch from '../components/LangSwitch/LangSwitch'
import './index.css'

const I18nData = {
  en: {
    Welcome: 'Welcome! Information about Thien Long Son - Dragon Mountain',
    NewsVirus: 'Due to Public Emergency (Corona virus), the charitable vegetarian lunch on Sundays is stopped temporarily until further notice. Thanks.'
  },
  vn: {
    Welcome: 'Công viên tâm linh Thiên Long Sơn',
    NewsVirus: 'Vì nạn dịch Virus Corona, buổi cơm Họp Mặt mỗi Chủ Nhật sẽ tạm ngưng một thời gian.'
  }
}
let I18n = I18nData[(typeof window !== 'undefined' && localStorage.getItem('lang')) || 'vn'] // default to 'vn'ulti

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Nav />
    <img alt="banner" className="roundedImg" src="https://i.ibb.co/6HK8Yby/image.png" />

    <LangSwitch />

    <p>
      <span style={{ fontSize: 36 }} role="img" aria-label="emoji">🧘</span> {I18n.Welcome} <span style={{ fontSize: 36 }} role="img" aria-label="emoji">🐉</span> <span style={{ fontSize: 36 }} role="img" aria-label="emoji">⛰</span>
    </p>

    <Youtube video="7nfty6SshKY" autoplay="0" rel="0" modest="1" />

    <h3>News:</h3>
    <ul>
      <li>{I18n.NewsVirus}</li>
      <li>
        <Link to="/calendar/">Calendar / Events</Link>
      </li>
    </ul>

    <iframe
      src="https://calendar.google.com/calendar/embed?src=1sfhh7jb40fjjrb6h5db12oaro%40group.calendar.google.com&ctz=America%2FLos_Angeles&mode=AGENDA"
      width="100%"
      height="300"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      title="newsgatsb"
    >
      Loading…
    </iframe>

    <h3>6 Không:</h3>
    <table>
      <tr>
        <td>
          <ul>
            <li>Không Danh</li>
            <li>Không Lợi</li>
            <li>Không Vận Động Gây Quỹ</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>Không Chính Trị</li>
            <li>Không Liên Hệ Bất Kỳ Tổ Chức Nào</li>
            <li>Không Làm Phiền Ai</li>
          </ul>
        </td>
      </tr>
    </table>

    <a
      href="https://i.ibb.co/8zDdrGF/image.png"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        alt="info1"
        className="roundedImg"
        style={{ height: 500 }}
        src="https://i.ibb.co/8zDdrGF/image.png"
      />
    </a>

    <a
      href="https://i.ibb.co/3WJ7C43/image.png"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        alt="info2"
        className="roundedImg"
        style={{ height: 500, marginLeft: 20 }}
        src="https://i.ibb.co/3WJ7C43/image.png"
      />
    </a>
  </Layout>
)

export default IndexPage
