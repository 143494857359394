import React from 'react'
import './LangSwitch.css'

const I18nData = {
  en: {
    English: 'English',
    Vietnamese: 'Vietnamese'
  },
  vn: {
    English: 'English',
    Vietnamese: 'Vietnamese'
  }
}
let I18n = I18nData[(typeof window !== 'undefined' && localStorage.getItem('lang')) || 'vn'] // default to 'vn'

export default props => {
  const setLang = (lang) => {
    localStorage.setItem('lang', lang);
    window.location.reload();
  }
  return (
    <nav className="langs">
      <li onClick={() => setLang('en')}>{I18n.English}</li>
      <li onClick={() => setLang('vn')}>{I18n.Vietnamese}</li>
    </nav>
  )
}
